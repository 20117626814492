import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import NavBar from "../components/navBarSite"
import Footer from "../components/footer"

export function useMedia(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addEventListener('change', listener);
    return () => media.removeEventListener('change', listener);
  }, [matches, query]);

  return matches;
}

export default function BlogPost({ data }) {
  const post = data.markdownRemark;
  const isLaptop = useMedia('(min-width: 1225px)');

  return (
    <>
      <NavBar></NavBar>
      <div style={{ marginTop: 100, paddingLeft: isLaptop ? "20%" : 50, paddingRight: isLaptop ? "20%" : 50 }}>
        <h1 className="title has-text-centered" style={{ color: "#F56D44", fontWeight: "bold", fontSize: isLaptop ? 45 : 35 }}>{post.frontmatter.title}</h1>
        <p className="has-text-centered">{new Intl.DateTimeFormat("fr-FR", { dateStyle: "long" }).format(Date.parse(post.frontmatter.date))} par {post.frontmatter.author}</p>
        <br></br>
        <br></br>
        <div dangerouslySetInnerHTML={{ __html: post.html }} className="content" />
        {/* <div style={styles.containerLinks}>
          <a href={''} style={styles.link}>
            <img
              src={"/assets/img/arrow_left.png"}
              alt="profile"
              style={{ marginRight: 14 }}
            ></img>
            PRECEDENT
          </a>
          <a href={''} style={styles.link}>SUIVANT
            <img
              src={"/assets/img/arrow_right.png"}
              alt="profile"
              style={{ marginLeft: 14 }}
            ></img>
          </a>
        </div> */}
      </div>
      <Footer></Footer>
    </>
  )
}

const styles = {
  link: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: "#F56D44",
    fontSize: "14px",
    fontWeight: 700,
    marginBottom: 25,
    marginTop: 25
  },
  containerLinks: {
    display: "flex",
    justifyContent: "space-between",
  }
}

export const query = graphql`
  query BlogQuery($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
        author
      }
    }
  }
`